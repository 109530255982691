<template>
  <div
    v-if="
      getUserRoles() == Admin ||
      getUserRoles() == SuperAdmin ||
      getUserRoles() == DataEntry
    "
    class="d-flex justify-content-end align-items-center"
  >
    <b-button variant="primary" class="mx-1" @click="edit">تعديل</b-button>
     <b-button variant="outline-primary" @click="$router.go(-1)">تراجع </b-button>
    <b-button
      variant="outline-danger"
      class="ml-1"
      @click="removeCourse(courseDetailsDto.id)"
      >حذف</b-button
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry } from "@/router";
export default {
  computed: {
    ...mapState({
        DetailsPreviousExams: (state) =>
                state.previousExams.DetailsPreviousExams,    }),
  },
  data() {
    return {
      SuperAdmin,
      Admin,
      DataEntry,
    };
  },
  methods: {
    ...mapActions(["updatePreviouseExam", "copyCourse"]),
    getUserRoles,
    removeCourse(id) {
      this.$store.dispatch("deletePreviousExam", id);
      console.log(id);
    },
    edit() {
      this.updatePreviouseExam(this.DetailsPreviousExams);
    },
    
  },
};
</script>
